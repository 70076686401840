import Loader from "Utility/loader";
import React, { Component, Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { components } from "react-select";

const LoadSuspense = (Component) => (props) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

const Login = LoadSuspense(lazy(() => import("../pages/Authentication/Login")));

const Logout = LoadSuspense(
  lazy(() => import("../pages/Authentication/Logout"))
);
const Register = LoadSuspense(
  lazy(() => import("../pages/Authentication/Register"))
);
const ForgetPwd = LoadSuspense(
  lazy(() => import("../pages/Authentication/ForgetPassword"))
);

const Dashboard = LoadSuspense(lazy(() => import("../pages/Dashboard/index")));
const HeiApplication = LoadSuspense(
  lazy(() => import("../HEI-UI/HeiApplication/HeiApplication"))
);
const Assinged_Application = LoadSuspense(
  lazy(() => import("AIC-UI/Application/AIC_Assinged_Applicatio"))
);
const UserManagement = LoadSuspense(
  lazy(() => import("Admin-UI/UserManagement/User_Management"))
);
const AddUser = LoadSuspense(
  lazy(() => import("Admin-UI/UserManagement/Add_User"))
);
const Edit_Application = LoadSuspense(
  lazy(() => import("Admin-UI/Edit_Application"))
);
const Feedback_Form = LoadSuspense(
  lazy(() => import("Admin-UI/Feedback_Form"))
);
const QuestionCrud = LoadSuspense(
  lazy(() => import("Admin-UI/QuestionManagement/QuestionCrud"))
);
const All_Question = LoadSuspense(
  lazy(() => import("Admin-UI/QuestionManagement/Question"))
);
const Criterion_Based_Question = LoadSuspense(
  lazy(() => import("Admin-UI/QuestionManagement/Criterion_Based_Question"))
);
const All_Aic = LoadSuspense(
  lazy(() => import("Admin-UI/AicManagement/All_Aic"))
);
const Invite_Aic = LoadSuspense(
  lazy(() => import("Admin-UI/AicManagement/inviteAic"))
);
const Visit_Type = LoadSuspense(
  lazy(() => import("Admin-UI/VisitManagement/Visit_Type"))
);
const Add_Permission = LoadSuspense(
  lazy(() => import("Admin-UI/UserManagement/PermissionCrud/Add_Permission"))
);
const Add_Hei_User = LoadSuspense(
  lazy(() => import("HEI-UI/UserManagement/Add_Hei_User"))
);
const Profile_Page = LoadSuspense(lazy(() => import("AIC-UI/Profile_Page")));
const Audit_Page = LoadSuspense(lazy(() => import("Admin-UI/Audit_Page")));
const Teachers = LoadSuspense(
  lazy(() => import("HEI-UI/FacultyManagement/Teachers"))
);
const Stud = LoadSuspense(lazy(() => import("HEI-UI/StudentManagement/stud")));
const AddStudent = LoadSuspense(
  lazy(() => import("HEI-UI/StudentManagement/Add_Student"))
);
const Addteacher = LoadSuspense(
  lazy(() => import("HEI-UI/FacultyManagement/Add_teacher"))
);
const EditProfile = LoadSuspense(lazy(() => import("HEI-UI/EditProfile")));
const Edit_Question = LoadSuspense(
  lazy(() => import("Admin-UI/QuestionManagement/EditQuestion"))
);
const CreateAic = LoadSuspense(
  lazy(() => import("pages/Authentication/create_Aic"))
);
const NtcAllApplication = LoadSuspense(
  lazy(() => import("Admin-UI/Applications/AllApplications"))
);
const ApplicationList = LoadSuspense(
  lazy(() => import("HEI-UI/HeiApplication/ApplicationList"))
);
const SarForm = LoadSuspense(
  lazy(() => import("HEI-UI/HeiApplication/SarForm"))
);
const HeiApplicationTimeline = LoadSuspense(
  lazy(() => import("HEI-UI/HeiApplication/ApplicationTimeline"))
);
const AdminApplicationTimeline = LoadSuspense(
  lazy(() => import("Admin-UI/Applications/ApplicationTimline"))
);
const AicApplicationTimline = LoadSuspense(
  lazy(() => import("AIC-UI/Application/ApplicationTimline"))
);
const AicEducationPage = LoadSuspense(
  lazy(() => import("AIC-UI/Components/AIC/Pages/AicEducationPage"))
);
const AicTrianingPage = LoadSuspense(
  lazy(() => import("AIC-UI/Components/AIC/Pages/AicTrianing"))
);
const AicPublicationsPage = LoadSuspense(
  lazy(() => import("AIC-UI/Components/AIC/Pages/AicPublications"))
);
const AicExperiencePage = LoadSuspense(
  lazy(() => import("AIC-UI/Components/AIC/Pages/AicExperiencePage"))
);
const AdminTeachers = LoadSuspense(
  lazy(() => import("Admin-UI/Faculty/FacultyListing"))
);
const AdminStudent = LoadSuspense(
  lazy(() => import("Admin-UI/student/AdminStudent"))
);
const HeiListing = LoadSuspense(
  lazy(() => import("Admin-UI/HeiManagement/HeiListing"))
);
const HeiUser = LoadSuspense(
  lazy(() => import("Admin-UI/HeiUserManagement/HeiUser"))
);
const ApplicationPermission = LoadSuspense(
  lazy(() => import("Admin-UI/ApplicationPermission"))
);
const Programs = LoadSuspense(lazy(() => import("Admin-UI/Programs/Programs")));
const RatingListing = LoadSuspense(
  lazy(() => import("Admin-UI/RatingAndScore/Ratinglisting"))
);
const Report = LoadSuspense(lazy(() => import("Report/Report")));
const CriterionWeightage = LoadSuspense(
  lazy(() => import("Admin-UI/CriterionWeightage/CriterionWeightage"))
);
const AdminNotifications = LoadSuspense(
  lazy(() => import("Admin-UI/Notifications"))
);
const HeiIntroduction = LoadSuspense(
  lazy(() => import("HEI-UI/HeiIntroduction/HeiIntroduction"))
);
const ProgramBatches = LoadSuspense(
  lazy(() => import("Admin-UI/Programs/ProgramBatches"))
);
const HeiIntro = LoadSuspense(lazy(() => import("Admin-UI/HeiIntro")));
const Permission = LoadSuspense(
  lazy(() => import("Admin-UI/Permission/Permission"))
);
const UserList = LoadSuspense(
  lazy(() => import("HEI-UI/UserManagement/UserList"))
);
const Education = LoadSuspense(
  lazy(() => import("Admin-UI/AicManagement/AicProfilePages/Education"))
);
const Publication = LoadSuspense(
  lazy(() => import("Admin-UI/AicManagement/AicProfilePages/Publication"))
);
const Trianing = LoadSuspense(
  lazy(() => import("Admin-UI/AicManagement/AicProfilePages/Trianing"))
);
const Experience = LoadSuspense(
  lazy(() => import("Admin-UI/AicManagement/AicProfilePages/Experience"))
);
const HeiDetail = LoadSuspense(
  lazy(() => import("Admin-UI/HeiManagement/HeiUserDetail"))
);
const SarPreview = LoadSuspense(
  lazy(() => import("HEI-UI/HeiApplication/SarPreview/SarPreview"))
);
const HeiStudentandFaculty = LoadSuspense(
  lazy(() => import("HEI-UI/HeiApplication/HeiStudentandFaculty"))
);
const AicProfile = LoadSuspense(
  lazy(() => import("Admin-UI/AicManagement/AicProfilePages/Aic_Profile"))
);

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  { path: "/all-application", component: <NtcAllApplication /> },
  { path: "/editapplication", component: <Edit_Application /> },

  { path: "/heiapplication", component: <HeiApplication /> },
  { path: "/sarform", component: <SarForm /> },
  { path: "/hei-application-timeline", component: <HeiApplicationTimeline /> },
  { path: "/Rating", component: <RatingListing /> },
  { path: "/criterion-weightage", component: <CriterionWeightage /> },

  {
    path: "/admin-application-timeline",
    component: <AdminApplicationTimeline />,
  },
  {
    path: "/aic-application-timeline",
    component: <AicApplicationTimline />,
  },
  { path: "/ApplicationList", component: <ApplicationList /> },

  { path: "/FeedbackForm", component: <Feedback_Form /> },
  { path: "/Aic", component: <All_Aic /> },
  { path: "/inviteAic", component: <Invite_Aic /> },
  { path: "/Visits", component: <Visit_Type /> },
  { path: "/AicProfile", component: <Profile_Page /> },
  { path: "/facultylisting", component: <AdminTeachers /> },
  { path: "/studentlisting", component: <AdminStudent /> },
  { path: "/heilisting", component: <HeiListing /> },
  { path: "/heiuser", component: <HeiUser /> },
  { path: "/ApplicationPermission", component: <ApplicationPermission /> },
  { path: "/heiDetail", component: <HeiDetail /> },
  { path: "/sarPreview", component: <SarPreview /> },
  { path: "/studentandfaculty", component: <HeiStudentandFaculty /> },

  { path: "/AicEducation", component: <AicEducationPage /> },
  { path: "/AicTrianing", component: <AicTrianingPage /> },
  { path: "/AicPublication", component: <AicPublicationsPage /> },
  { path: "/AicExperience", component: <AicExperiencePage /> },
  { path: "/AuditPage", component: <Audit_Page /> },
  { path: "/Student/application/", component: <Stud /> },
  { path: "/Faculty", component: <Teachers /> },
  { path: "/addstudent", component: <AddStudent /> },
  { path: "/addheiuser", component: <Add_Hei_User /> },
  { path: "/hei-users", component: <UserList /> },

  { path: "/addteacher", component: <Addteacher /> },
  { path: "/EditProfile", component: <EditProfile /> },
  { path: "/assingedapplication", component: <Assinged_Application /> },
  { path: "/users", component: <UserManagement /> },
  { path: "/addUser", component: <AddUser /> },

  { path: "/Question", component: <All_Question /> },
  { path: "/heiintroduction", component: <HeiIntroduction /> },
  { path: "/AddQuestion", component: <QuestionCrud /> },
  { path: "/Programs", component: <Programs /> },
  { path: "/program/batches", component: <ProgramBatches /> },
  { path: "/AicDetailProfile", component: <AicProfile /> },
  {
    path: "/Criterionbasedquestion",
    component: <Criterion_Based_Question />,
  },
  { path: "/EditQuestion", component: <Edit_Question /> },

  { path: "/addpermission", component: <Add_Permission /> },
  { path: "/HeiUser", components: <Add_Hei_User /> },
  { path: "/Educations", component: <Education /> },
  { path: "/Publications", component: <Publication /> },
  { path: "/Trianings", component: <Trianing /> },
  { path: "/Experiences", component: <Experience /> },

  { path: "/report", component: <Report /> },
  { path: "/heiintro", component: <HeiIntro /> },
  { path: "/notification", component: <AdminNotifications /> },
  { path: "/Permission", component: <Permission /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/login" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/createaic", component: <CreateAic /> },

  // Authentication Inner
];

export { authProtectedRoutes, publicRoutes };
