import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Card, Collapse } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { NavDropdown } from "react-bootstrap";

//i18n
import { withTranslation } from "react-i18next";
import "./styles.css";
import { connect } from "react-redux";
import { PERMISSION_CONFIGS, ROLES } from "constant/constants";
import { get } from "helper/apihelper";
import { getPermissionConfigs } from "Utility/permissionConfigs";

const navItemMapping = {
  Dashboard: {
    type: "NavItem",
    to: "/dashboard",
    text: "Dashboard",
    iconClass: "bx-tone",
  },
  Application: {
    type: "NavItem",
    to: "/all-application",
    text: "Application",
    iconClass: "bx-tone",
  },
  "Visit Type": {
    type: "NavItem",
    to: "/Visits",
    text: "Visit Type",
    iconClass: "bx-customize",
  },
  Users: {
    type: "NavItem",
    to: "/users",
    text: "Users",
    iconClass: "bx-customize",
  },
  Hei: {
    type: "NavItem",
    to: "/heilisting",
    text: "HEI",
    iconClass: "bx-collection",
  },
  Criterion: {
    type: "NavItem",
    to: "/Question",
    text: "Criterions",
    iconClass: "bx-collection",
  },
  Aic: {
    type: "NavItem",
    to: "/Aic",
    text: "Program Evaluator",
    iconClass: "bx-collection",
  },
  Setting: {
    type: "NavItemDropDown",
    text: "Setting",
    iconClass: "bx-customize",
    items: [
      {
        name: "Setting:Application-permission",
        to: "/ApplicationPermission",
        text: "Application Permission",
      },
      {
        name: "Setting:programs",
        to: "/Programs",
        text: "Programs",
      },
      {
        name: "Setting:rating",
        to: "/Rating",
        text: "Rating",
      },
      {
        name: "Setting:criterion weightage",
        to: "/criterion-weightage",
        text: "Criterion Weightage",
      },
      {
        name: "Setting:Permission",
        to: "/Permission",
        text: "Permission",
      },
    ],
  },
};
const NavItem = ({ text, to, Icon, onClick }) => {
  return (
    <li className="nav-item dropdown">
      <Link
        className="nav-link dropdown-toggle arrow-none text-light txtcolor  font-weight-normal nav-font-size"
        onClick={onClick}
        to={to}
        exact
      >
        {Icon && Icon}
        {text}
      </Link>
    </li>
  );
};

const NavItemDropDown = ({ text, Icon, Items }) => {
  return (
    <li className="nav-item dropdown mb-0">
      <NavDropdown
        title={
          <div className="d-flex gap-1 txtcolor nav-link m-0 p-0">
            {Icon && Icon}
            <p
              className="nav-link text-light txtcolor  font-weight-normal m-0 p-0"
              style={{
                fontFamily: "inherit",
                fontWeight: "normal",
                fontSize: 16,
                marginBottom: 0,
              }}
            >
              {text}
            </p>
          </div>
        }
        id={`${text}-dropdown`}
        drop="down"
      >
        {Items && Items}
      </NavDropdown>
    </li>
  );
};

const Navbar = (props) => {
  const [role, setRole] = useState({});
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const [config, setConfig] = useState();

  const toggle = () => {
    setIsOpen(!isOpen);
    //console.log('Toggle state:', isOpen);
  };

  const getPermission = async () => {
    let role = localStorage.getItem("Role");
    const id =
      role === "Admin"
        ? 1
        : role === ROLES.OFFICE_ASSITANT
        ? 6
        : role === ROLES.DEPUTY_DIRECTOR
        ? 7
        :role===ROLES.CHAIR_MAN?8
        : null;
    //console.log(id,'ID IS')
    if (!id) {
      return;
    }
    try {
      const Permission = await get(`app/permission/${id}`);
     
      const configs = getPermissionConfigs(Permission);
      
      setConfig(configs);
    } catch (e) {}
  };
  useEffect(() => {
    const handleStorageChange = () => {
      const storedValue = localStorage.getItem("Role");
    
      if (storedValue) {
        setRole(storedValue);
      }
    };
    handleStorageChange();
    getPermission();
  }, []);

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");

    removeActivation(items);
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active");
        }
      }
    }
  };

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  return (
    <React.Fragment>
      <div></div>
      <div className="topnav">
        <div className="container-fluid overflowY-auto">
          {role === "Admin" ? (
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu "
              id="navigation"
            >
              <Collapse
                isOpen={props.dropShow}
                className="navbar-collapse mt-2"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  {Object.entries(PERMISSION_CONFIGS).map(([key, value]) => {
                    const navItemProps = navItemMapping[key];
                    if (navItemProps) {
                      const { type, ...rest } = navItemProps;
                      if (type === "NavItem") {
                        return (
                          <NavItem
                            key={key}
                            {...rest}
                            onClick={
                              window.innerWidth < 992
                                ? props.navShow
                                : undefined
                            }
                            Icon={
                              <i
                                className={`bx ${rest.iconClass} me-2 text-light txtcolor font-weight-normal fs-6`}
                              ></i>
                            }
                          />
                        );
                      } else if (type === "NavItemDropDown") {
                        return (
                          <NavItemDropDown
                            key={key}
                            text={rest.text}
                            Icon={
                              <i
                                className="bx bx-customize me-2"
                                style={{
                                  color: "#F5F5F5",
                                  fontFamily: "inherit",
                                  fontWeight: "normal",
                                  fontSize: 18,
                                }}
                              ></i>
                            }
                            Items={
                              <div className="position-relative">
                                <Card className="position-absolute top-100 start-0">
                                  {rest.items.map((item, index) => (
                                    <NavDropdown.Item
                                      key={index}
                                      as={Link}
                                      to={item.to}
                                      drop="below"
                                      onClick={
                                        window.innerWidth < 992
                                          ? props.navShow
                                          : undefined
                                      }
                                    >
                                      {item.text}
                                    </NavDropdown.Item>
                                  ))}
                                </Card>
                              </div>
                            }
                          />
                        );
                      }
                    }
                    return null;
                  })}
                </ul>
              </Collapse>
            </nav>
          ) : role === ROLES.OFFICE_ASSITANT ||
            role === ROLES.DEPUTY_DIRECTOR ||role===ROLES.CHAIR_MAN ? (
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu "
              id="navigation"
            >
              <Collapse
                isOpen={props.dropShow}
                className="navbar-collapse mt-2"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  {Object.entries(PERMISSION_CONFIGS).map(([key, value]) => {
                    const navItemProps = navItemMapping[key];
                    if (navItemProps) {
                      const { type, ...rest } = navItemProps;
                      if (type === "NavItem" && config && config[key]) {
                        return (
                          <NavItem
                            key={key}
                            {...rest}
                            onClick={
                              window.innerWidth < 992
                                ? props.navShow
                                : undefined
                            }
                            Icon={
                              <i
                                className={`bx ${rest.iconClass} me-2 text-light txtcolor font-weight-normal fs-6`}
                              ></i>
                            }
                          />
                        );
                      } else if (
                        type === "NavItemDropDown" &&
                        config &&
                        config[key]
                      ) {
                        return (
                          <NavItemDropDown
                            key={key}
                            {...rest}
                            Icon={
                              <i
                                className={`bx ${rest.iconClass} me-2`}
                                style={{
                                  color: "#F5F5F5",
                                  fontFamily: "inherit",
                                  fontWeight: "normal",
                                  fontSize: 18,
                                }}
                              ></i>
                            }
                            Items={
                              <>
                                {rest.items
                                  .filter((item) => config && config[item.name])
                                  .map((item, index) => (
                                    <NavDropdown.Item
                                      as={Link}
                                      to={item.to}
                                      onClick={
                                        window.innerWidth < 992
                                          ? props.navShow
                                          : undefined
                                      }
                                    >
                                      {item.text}
                                    </NavDropdown.Item>
                                  ))}
                              </>
                            }
                          />
                        );
                      }
                    }
                    return null;
                  })}
                </ul>
              </Collapse>
            </nav>
          ) : role === "HEI" ? (
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <Collapse
                isOpen={props.dropShow}
                className="navbar-collapse mt-2"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <NavItem
                    to={"/dashboard"}
                    text={"Dashboard"}
                    onClick={
                      window.innerWidth < 992 ? props.navShow : undefined
                    }
                    Icon={
                      <i className="bx bx-tone me-2 text-light txtcolor font-weight-normal fs-6"></i>
                    }
                  />
                  <NavItem
                    to={"/ApplicationList"}
                    text={"Application"}
                    onClick={
                      window.innerWidth < 992 ? props.navShow : undefined
                    }
                    Icon={
                      <i className="bx bx-tone me-2 text-light font-weight-normal fs-6"></i>
                    }
                  />
                  <NavItem
                    to={"/hei-users"}
                    text={"Users"}
                    onClick={
                      window.innerWidth < 992 ? props.navShow : undefined
                    }
                    Icon={
                      <i className="bx bx-tone me-2 text-light font-weight-normal fs-6"></i>
                    }
                  />
                  <NavItem
                    to={"/Faculty"}
                    text={"Faculty"}
                    onClick={
                      window.innerWidth < 992 ? props.navShow : undefined
                    }
                    Icon={
                      <i className="bx bx-tone me-2 text-light font-weight-normal fs-6"></i>
                    }
                  />
                  {/* <NavItem
                    to={"/Student"}
                    text={"Student"}
                    onClick={
                      window.innerWidth < 992 ? props.navShow : undefined
                    }
                    Icon={
                      <i className="bx bx-tone me-2 text-light font-weight-normal fs-6"></i>
                    }
                  /> */}
                </ul>
              </Collapse>
            </nav>
          ) : (
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={props.dropShow}
                className="navbar-collapse mt-2"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <NavItem
                    to={"/dashboard"}
                    text={"Dashboard"}
                    onClick={
                      window.innerWidth < 992 ? props.navShow : undefined
                    }
                    Icon={
                      <i className="bx bx-tone me-2 text-light txtcolor font-weight-normal fs-6"></i>
                    }
                  />
                  <NavItem
                    to={"/assingedapplication"}
                    text={"Application"}
                    onClick={
                      window.innerWidth < 992 ? props.navShow : undefined
                    }
                    Icon={
                      <i
                        className="bx bx-tone me-2"
                        style={{
                          color: "#F5F5F5",
                          fontFamily: "inherit",
                          fontWeight: "normal",
                          fontSize: 18,
                        }}
                      />
                    }
                  />
                  <NavItemDropDown
                    text={"Profile"}
                    Icon={
                      <i
                        className="bx bx-customize me-2"
                        style={{
                          color: "#F5F5F5",
                          fontFamily: "inherit",
                          fontWeight: "normal",
                          fontSize: 18,
                        }}
                      ></i>
                    }
                    Items={
                      <div className="position-relative">
                        <Card className="position-absolute top-100 start-0">
                          <NavDropdown.Item
                            as={Link}
                            to="/AicEducation"
                            onClick={
                              window.innerWidth < 992
                                ? props.navShow
                                : undefined
                            }
                          >
                            Education
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            as={Link}
                            to="/AicTrianing"
                            onClick={
                              window.innerWidth < 992
                                ? props.navShow
                                : undefined
                            }
                          >
                            Trianing
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            as={Link}
                            to="/AicExperience"
                            onClick={
                              window.innerWidth < 992
                                ? props.navShow
                                : undefined
                            }
                          >
                            Experience
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            as={Link}
                            to="/AicPublication"
                            onClick={
                              window.innerWidth < 992
                                ? props.navShow
                                : undefined
                            }
                          >
                            Publication
                          </NavDropdown.Item>
                        </Card>
                      </div>
                    }
                  />
                </ul>
              </Collapse>
            </nav>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
  roles: PropTypes.any,
  navShow: PropTypes.func,
  dropShow: PropTypes.bool,
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
